import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AlumnosView from '@/views/AlumnosView.vue'

const routes = [
  {
    path: '/home', 
    component: HomeView,
    name: 'home'
  },  
  {
    path: '/alumnos', 
    component: AlumnosView,
    name: 'alumnos'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
