<template>
  <div>
    <router-link :to="{ name: 'home' }" class="link">
      Home
    </router-link>
    <router-link :to="{ name: 'alumnos' }" class="link">
      Alumnos
    </router-link>

  </div>

  <div>
    <router-view>

    </router-view>
  </div>
</template>

<script>
export default {
    name: 'App',
  }
</script>

<style lang="scss">
.link {
  padding: 1em;  
  text-decoration-style: none;
}
</style>
