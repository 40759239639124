<template>
    <div class="alumnos">
        <di class="alumnos_izquierda">
            <h1>Aqui van las opciones</h1>
        </di>
        <di class="alumnos_derecha">
            <h1> Aqui van los detalles de las opciones </h1>
        </di>

    </div>
</template>

<script>
  
</script>

<style lang="scss" scoped>
.alumnos {
    padding: 1em;
    display: flex;
    &_alumnos_izquierda{
        width: 40%;
        border: 1px salmon;
    }
    &_alumnos_izquierda{
        width: 60%;
    }
}
  
</style>